.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}
a {
  text-decoration: none;
}
.logo_sidebar {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
}
.secend_text_learn_english {
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.image_sidebar {
  width: 100px;
  margin-right: 15px;
}
.first_text_takiacademy {
  color: #2065d1;
  font-weight: bold;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.firstandsecande_text {
  display: none;
}
.image_sidebar_after {
  width: 40px;
  margin-top: -15px;
  margin-bottom: 50px;
  margin: auto;
}
